$(document).ready(function($) {


    $("input.telefone")
        .mask("(99) 9999-9999?9")
        .focusout(function (event) {
            var target, phone, element;
            target = (event.currentTarget) ? event.currentTarget : event.srcElement;
            phone = target.value.replace(/\D/g, '');
            element = $(target);
            element.unmask();
            if(phone.length > 10) {
                element.mask("(99) 99999-999?9");
            } else {
                element.mask("(99) 9999-9999?9");
            }
        });


    //Slider principal home notícias destaques
    $('.slider-pousada').owlCarousel({
        loop:true,
        margin:0,
        nav:false,
        items:1,
        dots:true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        mouseDrag: false,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: false
    });

    $('.pousada-passaros').sticky({
        topSpacing:0
    });




    var dateFormat = "dd/mm/yy",
        from = $('#from'),
        to = $('#to');

   // from.val(); to.val('');
    from.datepicker({
        autoSize: true,
        showButtonPanel: false,
        numberOfMonths: 1,
        dateFormat: 'dd/mm/yy',
        dayNames: ['Domingo','Segunda','TerÃ§a','Quarta','Quinta','Sexta','SÃ¡bado'],
        dayNamesMin: ['D','S','T','Q','Q','S','S','D'],
        dayNamesShort: ['Dom','Seg','Ter','Qua','Qui','Sex','SÃ¡b','Dom'],
        monthNames: ['Janeiro','Fevereiro','MarÃ§o','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
        monthNamesShort: ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'],
        nextText: 'PrÃ³ximo',
        prevText: 'Anterior',
        closeText: 'OK',
        currentText: 'Hoje',
        minDate: 0
    }).datepicker("setDate", "0").on( "change", function() {
        to.datepicker( "option", "minDate", getDt( this ) );
    }),to.datepicker({
        dateFormat: 'dd/mm/yy',
        dayNames: ['Domingo','Segunda','TerÃ§a','Quarta','Quinta','Sexta','SÃ¡bado'],
        dayNamesMin: ['D','S','T','Q','Q','S','S','D'],
        dayNamesShort: ['Dom','Seg','Ter','Qua','Qui','Sex','SÃ¡b','Dom'],
        monthNames: ['Janeiro','Fevereiro','MarÃ§o','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
        monthNamesShort: ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'],
        nextText: 'Próximo',
        prevText: 'Anterior',
        closeText: 'OK',
        currentText: 'Hoje',
    });

    function getDt( element )
    {
        var date;
        try
        {
            date = $.datepicker.parseDate( dateFormat, element.value );
        }
        catch( error )
        {
            date = null;
        }
        return date;
    }


    //ativando galeria fotos
    $('[data-fancybox="images"]').fancybox({
        afterLoad : function(instance, current) {
            var pixelRatio = window.devicePixelRatio || 1;

            if ( pixelRatio > 1.5 ) {
                current.width  = current.width  / pixelRatio;
                current.height = current.height / pixelRatio;
            }
        }
    });

    //lazyload
    $('.lazy').Lazy();


    $(".duplo-standard").on('click', function() {

        $.fancybox.open([
            {
                src  : './assets/images/duplo-standard/duplo-varanda1.jpg',
                opts : {
                    caption : 'Quarto duplo standard com varanda'
                }
            },
            {
                src  : './assets/images/duplo-standard/duplo-varanda2.jpg',
                opts : {
                    caption : 'Quarto duplo standard com varanda'
                }
            },
            {
                src  : './assets/images/duplo-standard/duplo-varanda3.jpg',
                opts : {
                    caption : 'Quarto duplo standard com varanda'
                }
            },
            {
                src  : './assets/images/duplo-standard/duplo-varanda4.jpg',
                opts : {
                    caption : 'Quarto duplo standard com varanda'
                }
            }

        ], {
            loop : true,
            thumbs : {
                autoStart : false
            }
        });

    });


    $(".duplo-superior").on('click', function() {

        $.fancybox.open([
            {
                src  : './assets/images/duplo-superior/duplo-superior1.jpg',
                opts : {
                    caption : 'Quarto duplo superior com varanda'
                }
            },
            {
                src  : './assets/images/duplo-superior/duplo-superior2.jpg',
                opts : {
                    caption : 'Quarto duplo superior com varanda'
                }
            },
            {
                src  : './assets/images/duplo-superior/duplo-superior3.jpg',
                opts : {
                    caption : 'Quarto duplo superior com varanda'
                }
            },
            {
                src  : './assets/images/duplo-superior/duplo-superior4.jpg',
                opts : {
                    caption : 'Quarto duplo superior com varanda'
                }
            },
            {
                src  : './assets/images/duplo-superior/duplo-superior5.jpg',
                opts : {
                    caption : 'Quarto duplo superior com varanda'
                }
            },
            {
                src  : './assets/images/duplo-superior/duplo-superior6.jpg',
                opts : {
                    caption : 'Quarto duplo superior com varanda'
                }
            }

        ], {
            loop : true,
            thumbs : {
                autoStart : false
            }
        });

    });

    $(".triplo-standard").on('click', function() {

        $.fancybox.open([
            {
                src  : './assets/images/triplo-standard/triplo-standard1.jpg',
                opts : {
                    caption : 'Quarto triplo standard com varanda'
                }
            },
            {
                src  : './assets/images/triplo-standard/triplo-standard2.jpg',
                opts : {
                    caption : 'Quarto triplo standard com varanda'
                }
            },
            {
                src  : './assets/images/triplo-standard/triplo-standard3.jpg',
                opts : {
                    caption : 'Quarto triplo standard com varanda'
                }
            },
            {
                src  : './assets/images/triplo-standard/triplo-standard4.jpg',
                opts : {
                    caption : 'Quarto triplo standard com varanda'
                }
            },
            {
                src  : './assets/images/triplo-standard/triplo-standard5.jpg',
                opts : {
                    caption : 'Quarto triplo standard com varanda'
                }
            },
            {
                src  : './assets/images/triplo-standard/triplo-standard6.jpg',
                opts : {
                    caption : 'Quarto triplo standard com varanda'
                }
            },
            {
                src  : './assets/images/triplo-standard/triplo-standard7.jpg',
                opts : {
                    caption : 'Quarto triplo standard com varanda'
                }
            },

        ], {
            loop : true,
            thumbs : {
                autoStart : false
            }
        });

    });

    $(".triplo-superior").on('click', function() {

        $.fancybox.open([
            {
                src  : './assets/images/triplo-superior/triplo-superior1.jpg',
                opts : {
                    caption : 'Quarto triplo superior com varanda'
                }
            },
            {
                src  : './assets/images/triplo-superior/triplo-superior2.jpg',
                opts : {
                    caption : 'Quarto triplo superior com varanda'
                }
            },
            {
                src  : './assets/images/triplo-superior/triplo-superior3.jpg',
                opts : {
                    caption : 'Quarto triplo superior com varanda'
                }
            },
            {
                src  : './assets/images/triplo-superior/triplo-superior4.jpg',
                opts : {
                    caption : 'Quarto triplo superior com varanda'
                }
            }


        ], {
            loop : true,
            thumbs : {
                autoStart : false
            }
        });

    });

    $(".quadruplo-standard").on('click', function() {

        $.fancybox.open([
            {
                src  : './assets/images/quadruplo-standard/quadruplo-standard1.jpg',
                opts : {
                    caption : 'Quarto quadruplo standard com varanda'
                }
            },
            {
                src  : './assets/images/quadruplo-standard/quadruplo-standard2.jpg',
                opts : {
                    caption : 'Quarto quadruplo standard com varanda'
                }
            },
            {
                src  : './assets/images/quadruplo-standard/quadruplo-standard3.jpg',
                opts : {
                    caption : 'Quarto quadruplo standard com varanda'
                }
            },
            {
                src  : './assets/images/quadruplo-standard/quadruplo-standard4.jpg',
                opts : {
                    caption : 'Quarto quadruplo standard com varanda'
                }
            },
            {
                src  : './assets/images/quadruplo-standard/quadruplo-standard5.jpg',
                opts : {
                    caption : 'Quarto quadruplo standard com varanda'
                }
            },
            {
                src  : './assets/images/quadruplo-standard/quadruplo-standard6.jpg',
                opts : {
                    caption : 'Quarto quadruplo standard com varanda'
                }
            },
            {
                src  : './assets/images/quadruplo-standard/quadruplo-standard7.jpg',
                opts : {
                    caption : 'Quarto quadruplo standard com varanda'
                }
            }


        ], {
            loop : true,
            thumbs : {
                autoStart : false
            }
        });

    });

    $(".quadruplo-superior").on('click', function() {

        $.fancybox.open([
            {
                src  : './assets/images/quadruplo-superior/quadruplo-superior1.jpg',
                opts : {
                    caption : 'Quarto quadruplo superior com varanda'
                }
            },
            {
                src  : './assets/images/quadruplo-superior/quadruplo-superior2.jpg',
                opts : {
                    caption : 'Quarto quadruplo superior com varanda'
                }
            },
            {
                src  : './assets/images/quadruplo-superior/quadruplo-superior3.jpg',
                opts : {
                    caption : 'Quarto quadruplo superior com varanda'
                }
            },
            {
                src  : './assets/images/quadruplo-superior/quadruplo-superior4.jpg',
                opts : {
                    caption : 'Quarto quadruplo superior com varanda'
                }
            }

        ], {
            loop : true,
            thumbs : {
                autoStart : false
            }
        });

    });




    //responsivo

    $('.menu-mb').click(function(event) {
        event.preventDefault();
        var menuresponsivo = $('.menu-mobile');
        if ( jQuery(menuresponsivo).is(':hidden ') ) {
            jQuery(menuresponsivo).removeClass('slideOutUp');
            jQuery(menuresponsivo).show().addClass('slideInDown');
        } else {
            jQuery(menuresponsivo).removeClass('slideInDown');
            jQuery(menuresponsivo).addClass('slideOutUp');
            setTimeout(function() {
                jQuery(menuresponsivo).css({display: ''});
            }, 800);
        }
    });


    $('.chamar-rsv').click(function(event) {
        event.preventDefault();
        var menuresponsivo = $('.form-home');
        if ( jQuery(menuresponsivo).is(':hidden ') ) {
            jQuery(menuresponsivo).removeClass('slideOutUp');
            jQuery(menuresponsivo).show().addClass('slideInDown');
        } else {
            jQuery(menuresponsivo).removeClass('slideInDown');
            jQuery(menuresponsivo).addClass('slideOutUp');
            setTimeout(function() {
                jQuery(menuresponsivo).css({display: ''});
            }, 800);
        }
    });


});